import fileDownload from 'js-file-download'
import { useHttp } from '@/plugins/http'
import { useToast } from 'vue-toastification'

export default () => {
  const http = useHttp()
  const toast = useToast()
  const download = ({ url, name }) => http.get(url, { responseType: 'blob' }).then(({ data }) => {
    fileDownload(data, name)
  }).catch(() => toast.error('Не удалось скачать файл'))
  return {
    download
  }
}