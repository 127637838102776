<template>
  <div class="order-data order-data--history">
    <div class="container">
      <div class="order-data__inner">
        <h1>
          <content-loader
            viewBox="0 0 280 32"
            height="32"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <rect
              x="0"
              y="10"
              rx="0"
              ry="0"
              width="280"
              height="32"
            />
          </content-loader>
        </h1>
        <ul class="order-data__list">
          <li class="order-data__item">
            <div class="order-data__subtitle">
              Дата заказа
            </div>
            <div class="order-data__text">
              <content-loader
                viewBox="0 0 110 16"
                width="110"
                height="16"
                :speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
              >
                <rect
                  x="0"
                  y="0"
                  rx="0"
                  ry="0"
                  width="110"
                  height="16"
                />
              </content-loader>
            </div>
          </li>
          <li class="order-data__item">
            <div class="order-data__subtitle">
              Общая сумма
            </div>
            <div class="order-data__text">
              <content-loader
                viewBox="0 0 70 16"
                height="16"
                :speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
              >
                <rect
                  x="0"
                  y="0"
                  rx="0"
                  ry="0"
                  width="70"
                  height="16"
                />
              </content-loader>
            </div>
          </li>
          <li class="order-data__item">
            <div class="order-data__subtitle">
              Адрес доставки
            </div>
            <div class="order-data__text">
              <content-loader
                viewBox="0 0 180 16"
                height="16"
                :speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
              >
                <rect
                  x="0"
                  y="0"
                  rx="0"
                  ry="0"
                  width="180"
                  height="16"
                />
              </content-loader>
            </div>
          </li>
          <li class="order-data__item">
            <div class="order-data__subtitle">
              Компания
            </div>
            <div class="order-data__text">
              <content-loader
                viewBox="0 0 230 16"
                height="16"
                :speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
              >
                <rect
                  x="0"
                  y="0"
                  rx="0"
                  ry="0"
                  width="230"
                  height="16"
                />
              </content-loader>
            </div>
          </li>
          <li class="order-data__item">
            <div class="order-data__subtitle">
              Получатель
            </div>
            <div class="order-data__text">
              <content-loader
                viewBox="0 0 160 16"
                height="16"
                :speed="2"
                primary-color="#f3f3f3"
                secondary-color="#ecebeb"
              >
                <rect
                  x="0"
                  y="0"
                  rx="0"
                  ry="0"
                  width="160"
                  height="16"
                />
              </content-loader>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader
  }
}
</script>
