<template>
  <div class="table cart__table">
    <div class="caption" :class="captionClass">
      <div class="caption__inner">
        <div v-if="suborder.contract_name" class="caption__heading">
          Договор {{ suborder.contract_name }} <template v-if="suborder.contract_date">
            от {{ suborder.contract_date }}
          </template>
        </div>
        <div v-if="suborder.is_expired" class="caption__status">
          <svg class="s-icon s-icon--md caption__icon"><use :xlink:href="`/img/svg/sprite1.svg#warning`" /></svg>
          <div class="caption__text">
            Просрочен
          </div>
        </div>
        <template v-else-if="paymentStatusTitle || statusTitle">
          <div v-if="statusTitle" class="caption__status">
            <svg class="s-icon s-icon--md caption__icon"><use :xlink:href="`/img/svg/sprite1.svg#${statusIcon}`" /></svg>
            <div class="caption__text">
              {{ statusTitle }}
            </div>
          </div>
          <div v-if="paymentStatusTitle" class="caption__status">
            <svg class="s-icon s-icon--md caption__icon"><use :xlink:href="`/img/svg/sprite1.svg#${paymentStatusIcon}`" /></svg>
            <div class="caption__text">
              {{ paymentStatusTitle }}
            </div>
          </div>
        </template>
        <div v-else class="caption__status">
          <svg v-if="prepaymentStatusIcon" class="s-icon s-icon--md caption__icon"><use :xlink:href="`/img/svg/sprite1.svg#${prepaymentStatusIcon}`" /></svg>
          <div v-if="prepaymentStatusTitle" class="caption__text">
            {{ prepaymentStatusTitle }}
          </div>
        </div>
      </div>
    </div>
    <div class="table__head">
      <div class="table__th table__th--first">
        Наименование / Штрих-код
      </div>
      <div class="table__th table__th--second">
        Цена
      </div>
      <div class="table__th table__th--fourth">
        Количество
      </div>
      <div class="table__th table__th--third">
        Итого
      </div>
    </div>
    <suborder-item v-for="item in suborder.items" :key="item.product.id" :item="item" />
    <div class="table__footer">
      <a class="link-download table__footer-link" @click="copySuborderToCart">
        <span class="link-download__icon">
          <svg class="s-icon">
            <use xlink:href="/img/svg/sprite1.svg#repeat-gray" />
          </svg>
        </span>
        <span class="link-download__text">повторить подзаказ</span>
      </a>
      <template v-if="suborder.is_expired">
        <div class="table__footer-info">
          <span class="table__footer-icon">
            <svg class="s-icon">
              <use xlink:href="/img/svg/sprite1.svg#warning" />
            </svg>
          </span>
          Оплата просрочена, пожалуйста, повторите подзаказ
        </div>
      </template>
      <template v-else>
        <a
          v-if="suborder.payment_invoice"
          class="link-download table__footer-link"
          @click.prevent="download(suborder.payment_invoice)"
        >
          <span class="link-download__icon">
            <svg class="s-icon"><use xlink:href="/img/svg/sprite1.svg#receipt" /></svg>
          </span>
          <span class="link-download__text">Скачать счет</span>
        </a>
        <a
          v-if="suborder.consignment_invoice"
          class="link-download table__footer-link"
          @click.prevent="download(suborder.consignment_invoice)"
        >
          <span class="link-download__icon"><svg class="s-icon"><use xlink:href="/img/svg/sprite1.svg#receipt" /></svg></span>
          <span class="link-download__text">Скачать накладную</span>
        </a>
        <p v-if="suborder.payment_status !== PAID && suborder.payment_invoice?.due_date" class="table__footer-signature">
          Оплата до {{ suborder.payment_invoice.due_date }} г.
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import SuborderItem from './SuborderItem.vue'
import useDocumentDownload from '@/composables/useDocumentDownload'
import { computed } from 'vue'
import { getStatusTitle, getStatusIcon, CANCELLED } from '@/common/suborderStatus'
import {
  getStatusTitle as getPaymentStatusTitle,
  getStatusIcon as getPaymentStatusIcon,
  getStatusCaptionClass as getPaymentCaptionClass,
  PAID
} from '@/common/suborderPaymentStatus'
import {
  getStatusTitle as getPrepaymentStatusTitle,
  getStatusIcon as getPrepaymentStatusIcon,
  getStatusCaptionClass as getPrepaymentCaptionClass
} from '@/common/prepaymentStatus'
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: { SuborderItem },
  props: {
    suborder: {
      type: Object,
      required: true
    }
  },
  emits: ['showModal'],
  setup(props, { emit }) {
    const api = useApi()
    const toast = useToast()
    const router = useRouter()
    const store = useStore()

    const cartItemsCount = computed(() => store.getters.getCartItemsCount)
    const statusTitle = computed(() => getStatusTitle(props.suborder.status))
    const statusIcon = computed(() => getStatusIcon(props.suborder.status))
    const paymentStatusTitle = computed(() => getPaymentStatusTitle(props.suborder.payment_status))
    const paymentStatusIcon = computed(() => getPaymentStatusIcon(props.suborder.payment_status))
    const prepaymentStatusTitle = computed(() => getPrepaymentStatusTitle(props.suborder.prepayment_status))
    const prepaymentStatusIcon = computed(() => getPrepaymentStatusIcon(props.suborder.prepayment_status))

    const { download } = useDocumentDownload()

    const repeatSuborder = (clear = true) => {
      api.suborders.repeatSuborder(props.suborder.id, { clear }).then(() => {
        router.push('/cart')
      }).catch(() => {
        toast.error('Не удалось повторить подзаказ')
      })
    }
    const copySuborderToCart = () => {
      if (cartItemsCount.value > 0) {
        emit('showModal', { callback: repeatSuborder, title: 'Повторить подзаказ' })
      } else {
        repeatSuborder()
      }
    }
    const captionClass = computed(() => {
      if (props.suborder.is_expired || props.suborder.status === CANCELLED) {
        return 'caption--warning'
      } else if (paymentStatusTitle.value) {
        return getPaymentCaptionClass(props.suborder.payment_status)
      }
      return getPrepaymentCaptionClass(props.suborder.prepayment_status)
    })
    return {
      captionClass,
      PAID,
      copySuborderToCart,
      statusTitle,
      statusIcon,
      paymentStatusTitle,
      paymentStatusIcon,
      prepaymentStatusTitle,
      prepaymentStatusIcon,
      download
    }
  }
}
</script>
