<template>
  <section v-if="showPlaceholders">
    <order-details-placeholder
      class="order-data--history"
    />
    <div class="cart cart-order history-view">
      <div class="container">
        <div class="cart__inner">
          <suborder-placeholder v-for="index in placeholderCount" :key="index" />
        </div>
      </div>
    </div>
  </section>
  <section v-else>
    <order-details
      :order="order"
      :show-repeat-order="true"
      class="order-data--history"
      @show-modal="onShowModal"
    />
    <div class="cart cart-order history-view">
      <div class="container">
        <div class="cart__inner">
          <suborder
            v-for="suborder in order.suborders"
            :key="suborder.id"
            :suborder="suborder"
            @show-modal="onShowModal"
          />
        </div>
      </div>
    </div>
    <cart-modal v-model="showModal" :modal-callback="modalCallback" :modal-title="modalTitle" />
  </section>
</template>

<script>
import OrderDetails from '@/components/orders/OrderDetails.vue'
import OrderDetailsPlaceholder from '@/components/placeholders/OrderDetailsPlaceholder.vue'
import Suborder from '@/components/orders/Suborder.vue'
import SuborderPlaceholder from '@/components/placeholders/SuborderPlaceholder.vue'
import CartModal from '../components/CartModal.vue'
import { useApi } from '@/api'
import { useToast } from 'vue-toastification'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: {
    OrderDetailsPlaceholder,
    OrderDetails,
    Suborder,
    SuborderPlaceholder,
    CartModal
  },
  props: {
    id: {
      type: String,
      required: true
    },
    placeholderCount: {
      type: Number,
      default: 2
    }
  },
  setup(props) {
    const api = useApi()
    const toast = useToast()
    const router = useRouter()

    const showPlaceholders = ref(true)
    const order = ref({})
    const showModal = ref(false)
    const modalCallback = ref(() => { }) 
    const modalTitle = ref('')

    api.orders.fetchOrder(props.id, { expand: 'suborders.items,shipping_address' }).then(({ data }) => {
      order.value = data
    }).catch(() => {
      toast.error('Не удалось загрузить заказ')
      router.push('/orders')
    }).finally(() => {
      showPlaceholders.value = false
    })

    const onShowModal = ({ callback, title }) => {
      modalCallback.value = callback
      modalTitle.value = title
      showModal.value = true
    }

    return {
      onShowModal,
      modalTitle,
      showModal,
      modalCallback,
      showPlaceholders,
      order
    }
  }
}
</script>
