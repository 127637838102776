<template>
  <div class="table__list">
    <div class="table__col table__col--first">
      <div class="table__col-first">
        <content-loader
          viewBox="0 0 300 54"
          height="54"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width="54"
            height="54"
          /> 
          <rect
            x="70"
            y="4"
            rx="0"
            ry="0"
            width="260"
            height="16"
          /> 
          <rect
            x="70"
            y="30"
            rx="0"
            ry="0"
            width="120"
            height="14"
          />
        </content-loader>
      </div>
    </div>
    <div class="table__col table__col--second">
      <div class="table__col-second">
        <content-loader
          viewBox="0 0 80 54"
          :height="54"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="4"
            rx="0"
            ry="0"
            width="80"
            height="16"
          /> 
          <rect
            class="product-vat-placeholder"
            x="20"
            y="30"
            rx="0"
            ry="0"
            width="60"
            height="14"
          />
        </content-loader>
      </div>
    </div>
    <div class="table__col table__col--fourth">
      <div class="table__col-fourth">
        <content-loader
          viewBox="0 0 40 20"
          :height="20"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="4"
            rx="0"
            ry="0"
            width="40"
            height="16"
          /> 
        </content-loader>
      </div>
    </div>
    <div class="table__col table__col--third">
      <div class="table__col-third">
        <content-loader
          viewBox="0 0 70 20"
          :height="20"
          :speed="2"
          primary-color="#f3f3f3"
          secondary-color="#ecebeb"
        >
          <rect
            x="0"
            y="4"
            rx="0"
            ry="0"
            width="70"
            height="16"
          /> 
        </content-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  components: {
    ContentLoader
  }
}
</script>

<style lang="scss" scoped>
.product-vat-placeholder {
  @media screen and (max-width: 991px) {
    x: 0;
  }
}
</style>
