<template>
  <div class="order-data">
    <div class="container">
      <div class="order-data__inner">
        <h1 v-if="showOrderNumber">
          {{ order.number ? `Заказ №${order.number}` : "Новый заказ" }}
        </h1>
        <ul class="order-data__list">
          <li class="order-data__item">
            <div class="order-data__subtitle">
              Дата заказа
            </div>
            <div class="order-data__text">
              {{ date }}
            </div>
          </li>
          <li v-if="order.total" class="order-data__item">
            <div class="order-data__subtitle">
              Общая сумма
            </div>
            <div class="order-data__text">
              {{ total }}
            </div>
          </li>
          <li v-if="order.shipping_address" class="order-data__item">
            <div class="order-data__subtitle">
              Адрес доставки
            </div>
            <div class="order-data__text">
              {{ order.shipping_address.name || order.shipping_address.name }}
            </div>
          </li>
          <li v-if="order.company_name" class="order-data__item">
            <div class="order-data__subtitle">
              Компания
            </div>
            <div class="order-data__text">
              {{ order.company_name }}
            </div>
          </li>
          <li
            v-if="order.recipient_name || order.recipient_phone"
            class="order-data__item"
          >
            <div class="order-data__subtitle">
              Получатель
            </div>
            <div class="order-data__text">
              {{
                [order.recipient_name, order.recipient_phone]
                  .filter(Boolean)
                  .join(", ")
              }}
            </div>
          </li>
        </ul>
        <div class="order-actions order-actions__flex">
          <button
            v-if="order.can_cancel"
            class="btn btn--danger btn--icon cancel-order"
            @click="cancelOrder"
          >
            <span class="btn__icon">

              <svg class="s-icon">
                <use xlink:href="/img/svg/sprite1.svg#cancel" />
              </svg>
            </span>
            <span class="btn__text">Отменить заказ</span>
          </button>
          <button
            v-if="showRepeatOrder"
            class="btn btn--primary btn--icon"
            @click="copyOrderToCart"
          >
            <span class="btn__icon">
              <svg class="s-icon">
                <use xlink:href="/img/svg/sprite1.svg#repeat" />
              </svg>
            </span>
            <span class="btn__text">Повторить заказ</span>
          </button>
        </div>
      </div>
      <div v-if="showOrderText" class="message-info message-info--colorized order-data__info">
        <svg class="s-icon message-info__icon">
          <use xlink:href="/img/svg/sprite1.svg#info" />
        </svg>
        <div class="message-info__text">
          <p>
            Для внесение изменения в заказ, перейдите в раздел
            <router-link to="/orders">
              «История заказов»
            </router-link>, найдите нужный
            заказ и нажмите кнопку «Отменить заказ».
          </p>
          <p>
            Отмена заказа через сайт возможна <strong>в течение 10 минут</strong> после
            оформления заказа. Для внесения изменений по прошествии этого
            времени необходимо связаться со <button @click="showModal = true">
              службой поддержки
            </button>.
          </p>
        </div>
      </div>
    </div>
    <FeedbackModal v-model="showModal" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { formatCurrency } from "@/common/formatNumbers";
import {computed, ref} from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useApi } from "@/api";
import { useStore } from "vuex"
import FeedbackModal from "@/components/FeedbackModal";

export default {
  components: {
    FeedbackModal
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    showRepeatOrder: {
      type: Boolean,
      default: false,
    },
    showOrderNumber: {
      type: Boolean,
      default: true,
    },
    showOrderText: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["showModal"],
  setup(props, { emit }) {
    const api = useApi();
    const toast = useToast();
    const router = useRouter();
    const store = useStore();
    const showModal = ref(false)

    const date = computed(() =>
      dayjs.unix(props.order.created_at).format("DD.MM.YYYY HH:mm")
    );
    const total = computed(() => formatCurrency(props.order.total));
    const cartItemsCount = computed(() => store.getters.getCartItemsCount);

    const repeatOrder = (clear) => {
      api.orders
        .repeatOrder(props.order.id, { clear })
        .then(() => {
          router.push("/cart");
        })
        .catch(() => {
          toast.error("Не удалось повторить заказ");
        });
    };
    const copyOrderToCart = () => {
      if (cartItemsCount.value > 0) {
        emit("showModal", { callback: repeatOrder, title: "Повторить заказ" });
      } else {
        repeatOrder();
      }
    };
    const cancelOrder = () => {
      api.orders
        .cancelOrder(props.order.id)
        .then(() => {
          router.push("/orders");
        })
        .catch(() => {
          toast.error("Не удалось отменить заказ");
        });
    };
    return {
      cancelOrder,
      copyOrderToCart,
      date,
      total,
      showModal
    };
  },
};
</script>
